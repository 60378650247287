import React, { useState } from "react";
import { faEye, faEyeSlash } from "@fortawesome/free-regular-svg-icons";
import InputGroup from "../InputGroup/InputGroup.tsx";

interface PropTypes {
    name: string;
    onChange: (evt: React.ChangeEvent<HTMLInputElement>) => void;
    onKeyDown: (evt: React.KeyboardEvent<HTMLInputElement>) => void;
    value?: string | number | readonly string[] | undefined;
    isDisabled?: boolean;
    ariaLabel?: string;
    isEnableAutoFocus?:boolean;
}

const PasswordInput = ({
                           name,
                           onChange,
                           onKeyDown,
                           value,
                           isDisabled = false,
                           ariaLabel = name,
                           isEnableAutoFocus = false
} : PropTypes) => {

    const [showPassword, setShowPassword] = useState<boolean>(false);

    const handleTogglePasswordVisibility = () => {
        setShowPassword(prevState => !prevState);
    };

    return (
        <InputGroup
            name={name}
            type={showPassword ? "text" : "password"}
            value={value}
            rightIcon={showPassword ? faEyeSlash : faEye}
            onChange={onChange}
            onKeyDown={onKeyDown}
            onClick={() => handleTogglePasswordVisibility()}
            isDisabled={isDisabled}
            ariaLabel={ariaLabel}
            isEnableAutoFocus={isEnableAutoFocus}
        />
    );
};

export default PasswordInput;
